import * as types from './mutation-types'

const mutations = {
    // 更新主页选中的tab索引
    updateMainTabBarActive(state, active) {
        state.mainTabBarActive = active
    },
    [types.SET_ROUTE_TRANSITION](state, routeTransition) {
        state.routeTransition = routeTransition
    },
    [types.SET_TOKEN](state, token) {
        state.token = token
    },
    [types.SET_IS_LOGIN](state, isLogin) {
        state.isLogin = isLogin
    },
    [types.SET_UID](state, uid) {
        state.uid = uid
    },
    [types.SET_KEEP_ALIVE_ARR](state, arr) {
        state.keepAliveArr = arr
    },
    [types.SET_QUERY](state, query) {
        state.query = query
    }
}

export default mutations
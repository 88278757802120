import * as types from './mutation-types'

import { TOKEN_KEY, IS_LOGIN_KEY, SEARCH_HISTORY_KEY } from '../common/const'
import { login } from '../api/auth'
import { Notify } from "vant";

export const setRouteTransition = ({commit}, value) => {
  commit(types.SET_ROUTE_TRANSITION, value)
}

export const userLogin = async ({ commit },{username, password}) => {
  let result_data = await login(username, password,)
  if (result_data.code !== 200) {
    // 展示提示信息
    Notify({ type: 'danger', message: result_data.msg });
    return false
  }
  
  let { data, } = result_data
  commit(types.SET_TOKEN, data)
  commit(types.SET_IS_LOGIN, true)
  localStorage.setItem(TOKEN_KEY, data)
  localStorage.setItem(IS_LOGIN_KEY, true)
  return true
}

export const userLogout = ({ commit }) => {
  commit(types.SET_IS_LOGIN, false)
  localStorage.setItem(IS_LOGIN_KEY, false)
  localStorage.setItem(SEARCH_HISTORY_KEY, '')
}

export const addKeepAlive = ({commit,state}, componentName) => {
  let { keepAliveArr } = state
  if (keepAliveArr.indexOf(componentName) === -1) {
    keepAliveArr.push(componentName)
    commit(types.SET_KEEP_ALIVE_ARR, keepAliveArr)
  }
}

export const removeKeepAlive = ({commit, state}, componentName) => {
  let { keepAliveArr } = state
  let index = keepAliveArr.indexOf(componentName)
  if (index !== -1) {
    keepAliveArr.splice(index, 1)
    commit(types.SET_KEEP_ALIVE_ARR, keepAliveArr)
  }
}

export const setQuery = ({ commit }, query) => {
  commit(types.SET_QUERY, query)
}

import { TOKEN_KEY, IS_LOGIN_KEY } from '../common/const'

const state = {
    // 主页选中的tab索引
    mainTabBarActive: 0,
    
    routeTransition: true,
    token: localStorage.getItem(TOKEN_KEY) || '',
    isLogin: JSON.parse(localStorage.getItem(IS_LOGIN_KEY)) || false,
    keepAliveArr: ['MainPage'],
    query: ''
}

export default state
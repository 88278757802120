<template>
  <div id="app">

    <router-view v-slot="{ Component }">
      <transition appear>
        <keep-alive :include="keepAliveArr" :max="6">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>

  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  computed: {
    keepAliveArr() {
      return this.$store.state.keepAliveArr
    }
  },
  methods: {
    beforeEnter() {
      this.$store.dispatch('setRouteTransition', false)
    },
    afterEnter() {
      this.$store.dispatch('setRouteTransition', true)
    }
  }
}
</script>

<style lang="stylus">
@import "./assets/css/variable.styl"
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease 0s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}
</style>

import axios from 'axios'
import store from '../store'
import router from '../router'
import { apiUrl } from '../common/config'

const service = axios.create({
    baseURL: apiUrl.MCFUN_REQUEST_URL,
    timeout: 5000
})

service.interceptors.request.use(
    config => {
        // 在所有请求头统一添加token
        let { token, } = store.state
        if (token) {
            config.headers['Authorization'] = 'JWT ' + token
        }
        return config
    }
)

service.interceptors.response.use(res => {
    // 返回值状态为认证失败
    if (res.data && res.data.code === 401) {
        // 取消登录状态
        store.dispatch('userLogout')
        // 跳转到登录页面
        router.push({
            path: '/login',
            query: {
                from: router.path
            }
        })
    }
    return res
}, err => {
    return err.response
})


export default service
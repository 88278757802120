import { createRouter, createWebHistory } from 'vue-router'

import store from "@/store"

const Login = () => import('../views/login')
const MainPage = () => import('../views/home/MainPage')
const HomePage = () => import('../views/home/HomePage')
const CategoryPage = () => import('../views/article/CategoryPage')
const SearchPage = () => import('../views/search')
const MyPage = () => import('../views/my')
const HistoryPage = () => import('../views/my/HistoryPage')
const FavoritePage = () => import('../views/my/FavoritePage')
const ArticleListPage = () => import('../views/article')
const DetailPage = () => import('../views/detail')
const UserListPage = () => import('../views/user')
const AddUserPage = () => import('../views/user/AddUserPage')
const EditUserPage = () => import('../views/user/EditUserPage')
const AlbumPage = () => import('../views/article/AlbumPage')

const routes = [
    {
        path: '/',
        name: 'MainPage',
        redirect: {
            name: 'HomePage'
        },
        component: MainPage,
        meta: {
            isAuth: true
        },
        children: [
            { path: '/home', name: 'HomePage', component: HomePage },
            { path: '/category', name: 'CategoryPage', component: CategoryPage },
            { path: '/search', name: 'SearchPage', component: SearchPage },
            { path: '/my', name: 'MyPage', component: MyPage }
        ]
    },
    { path: '/login', name: 'Login', component: Login },
    { path: '/articles/:id/:title', name: 'ArticleListPage', component: ArticleListPage, meta: { isAuth: true } },
    { path: '/album/:id/:title', name: 'AlbumPage', component: AlbumPage, meta: { isAuth: true } },
    { path: '/detail/:id', name: 'Detail', component: DetailPage, meta: { isAuth: true } },
    { path: '/user', name: 'UserListPage', component: UserListPage, meta: { isAuth: true } },
    { path: '/user/add', name: 'AddUserPage', component: AddUserPage, meta: { isAuth: true } },
    { path: '/user/edit/:id', name: 'EditUserPage', component: EditUserPage, meta: { isAuth: true } },
    { path: '/my/history', name: 'HistoryPage', component: HistoryPage, meta: { isAuth: true } },
    { path: '/my/favorite', name: 'FavoritePage', component: FavoritePage, meta: { isAuth: true } },
    { page: '*', redirect: { name: 'HomePage' } }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


// TODO 切换页面时，判断是否已登录
router.beforeEach((to, from, next) => {
    if (to.meta.isAuth) {
        if (!store.state.isLogin) {
            next({
                path: '/login',
                query: {
                from: to.path
                }
            })
            return
        }
    }

    let active = 0
    switch (to.name) {
        case 'HomePage':
            active = 0
            break;

        case 'CategoryPage':
            active = 1
            store.dispatch('removeKeepAlive', 'ArticleListPage')    // 删除 文章列表的keepAlive
            break;

        case 'SearchPage':
            active = 2
            break;

        case 'MyPage':
            active = 3
            break;

        default:
            break;
    }

    // 更新主页选中的tab索引
    store.commit('updateMainTabBarActive', active)

    next()
})

export default router
import request from '../utils/request'

/**
 * 用户账号密码登录
 * @param {string} username 用户名
 * @param {string} password 密码
 * @returns 登录状态信息
 */
export const login = (username, password) => {
    let data = new URLSearchParams({
        "username": username,
        "password": password
    }).toString()
    
    return request.post('/login', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => res.data)
}

/**
 * 获取最新数据源地址
 * @returns 最新数据源地址
 */
export const getSourceDomain = () => {
    let data = ``
    return request.get(`/domain`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(res => res.data.data)
}